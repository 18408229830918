@mixin typography($name, $viewport: "small-viewport") {
  font-size: typography-size($name, $viewport);
  font-weight: typography-weight($name, $viewport);
  line-height: typography-line-height($name, $viewport);
  font-family: typography-font-family($name);
}

@mixin typography-size($name, $viewport: "small-viewport") {
  font-size: typography-size($name, $viewport);
  line-height: typography-line-height($name, $viewport);
}

@mixin typography-line-height($name, $viewport: "small-viewport") {
  line-height: typography-line-height($name, $viewport);
}

@mixin typography-weight($name, $viewport: "small-viewport") {
  font-weight: typography-weight($name, $viewport);
}