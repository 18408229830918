@font-face {
  font-family: "Noah";
  src: url("../fonts/noah/noah-regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Noah";
  src: url("../fonts/noah/noah-medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Noah";
  src: url("../fonts/noah/noah-bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Noah";
  src: url("../fonts/noah/noah-extra-bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Noah";
  src: url("../fonts/noah/noah-heavy.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Domine";
  src: url("../fonts/domine/static/domine-bold.ttf") format("truetype");
  font-weight: 500;
}

@import "../styles/normalize";
@import "../styles/media-query";
@import "../styles/typography";
@import "../styles/content";
@import "../styles/images";
@import "../styles/article";
@import "../styles/grid";
@import "../styles/auth-layout";
@import "../styles/components/roundel-list";
@import "../styles/components/library/field";
@import "../styles/components/progress-menu";
@import "../styles/components/library/pending";
@import "../styles/components/library/button";
@import "../styles/components/library/link-button";
@import "./property-data-display";
@import "../styles/landing";
@import "./components/faq";
@import "./components/numbered-list";

.visually-hidden {
  @include visually-hidden();
}

.firebase-emulator-warning {
  opacity: 0.4 !important;
}
