.property-data-section {
  & + .property-data-section {
    margin-top: 64px;
  }
}

.property-data-section__heading {
  font-size: 28px;
  line-height: 26px;
  margin: 0 0 16px;
  font-weight: 600;
}

.tabular-property-data {
  border-bottom: solid 2px #f0f0f0;
  display: flex;
  flex-direction: column;

  @include mq("medium-viewport") {
    display: table;
    width: 100%;
  }
}

.tabular-property-data__cell {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:before {
    content: attr(data-label);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-typography-meta;
    margin-right: 16px;

    @include mq("medium-viewport") {
      display: none;
    }
  }

  @include mq("medium-viewport") {
    &:first-child {
      padding-left: 0;
    }

  &:last-child {
      padding-right: 0;
    }
  }

  @include mq("medium-viewport") {
    padding: 12px 8px;
    display: table-cell;
  }
}

.tabular-property-data__cell-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: $color-typography-meta;
  display: none;

  @include mq("medium-viewport") {
    display: table-cell;
  }
}

.tabular-property-data__data-row {
  border-top: solid 2px #f0f0f0;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  flex-direction: column;

  @include mq("medium-viewport") {
    display: table-row;
  }
}

.tabular-property-data__cell--centered {
  text-align: center;
}

.tabular-property-data__cell--header {
  font-weight: 600;
}

.tabular-property-data__roundel {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .icon__canvas {
    width: 16px;
    height: auto;
  }

  .icon__glyph {
    fill: #fff;
  }
}

.tabular-property-data__roundel--success {
  background: #05c78b;
}

.tabular-property-data__roundel--danger {
  background: #e9153b;
}

.tabular-property-data__disclosure-button {
  all: inherit;
  margin: 0;
  cursor: pointer;

  &:hover {
    .icon__glyph {
      fill: $color-primary;
    }
  }

  &:focus {
    .icon__canvas {
      box-shadow: 0 0 0px 4px $field-content-focus-color;
    }
  }
}

.tabular-property-data__disclosure-button__signifier {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: -20px 0 -20px auto;
  width: 40px;

  .icon__canvas {
    border-radius: 50%;
  }

  .icon__glyph {
    @include transition();
  }
}

.tabular-property-data__cell--disclosure {
  width: 1%;
  
  @include mq("medium-viewport") {
    display: table-cell;
  }
}