
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

/**
 *----------------------------------------
  VARIABLES
 *----------------------------------------
 */
@import "../assets/styles/variables/button";

$account-layout-max-width: $layout-max-width;
$account-layout-navigation-background-color: #f5f9f9;
$account-layout-navigation-max-width: 400px;

.body--site-account-navigation {
  overflow: hidden;
}

/**
 *----------------------------------------
  HEADER
 *----------------------------------------
 */

/**
  * 1) Prevents the header form hiding is the site content doesn't wrap to the viewport.
  *    It will still "break" the layout but at it will be usable.
  */
.site-account-header {
  transform: translate3d(0, 0, 0);
  background: #fff;
  display: flex;
  height: $header-height;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw; /* 1 */
  z-index: 300;
}

.site-account-header--onboarding {
  background: #fff;
}

.site-account-header__content {
  display: flex;
  position: relative;
  margin: 0 auto;
  max-width: $account-layout-max-width;
  width: 100%;
}

.site-account-logo {
  display: block;
  left: $site-gutter;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.site-account-logo__graphic {
  height: 32px;
  width: auto;
}

@include mq("medium-viewport") {
  .site-account-header--onboarding {
    background: transparent;
  }

  .site-account-header {
    background: transparent;
    position: static;
    height: auto;

    .site-account-logo {
      transform: none;
      position: static;
      margin: 24px 0;
    }

    .site-account-logo--scale-strong {
      margin: 16px 0;
      width: inherit;

      .site-account-logo__graphic {
        height: 56px;
      }
    }
  }

  .site-account-logo__graphic {
    height: 40px;
    width: auto;
  }

  .site-account-header__content {
    align-items: center;
  }
}

/**
 *----------------------------------------
  NAVIGATION
 *----------------------------------------
 */

.site-account-navigation {
  display: flex;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  z-index: 1;
}

.site-account-navigation--active {
  z-index: 10;

  .site-account-navigation__main {
    left: 0;
  }
}

.site-account-navigation__header {
  align-items: center;
  display: flex;
  min-height: $header-height;
  position: relative;
}

.site-account-navigation__main {
  @include transition("left");
  background-color: #fff;
  height: 100vh;
  left: 100%;
  padding-top: $header-height;
  position: absolute;
  top: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: 100%;
}

.site-account-navigation__action {
  display: block;
  margin-right: 64px;
}

.site-account-navigation__main__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 gutter() * 7
    calc(#{stack("stronger")} + env(safe-area-inset-bottom));
}

.site-account-navigation-menu__collection {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-account-navigation-menu__item {
  display: inline;
  font-weight: 500;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;

  & + .site-account-navigation-menu__item {
    margin-top: stack();
  }
}

/**
 * 1. Used to style a button element.
 */
.site-account-navigation-menu__link {
  @include transition();

  background: transparent;
  border: 0;
  border-radius: 12px;
  color: $content-color-typography;
  cursor: pointer;
  /* 1 */
  display: inline-block;
  font-family: inherit;
  /* 1 */
  font-size: 16px;
  /* 1 */
  font-weight: 500;
  /* 1 */
  outline: none;
  padding: stack() 0;
  position: relative;
  /* 1 */
  text-decoration: none;
  text-align: center;
  /* 1 */

  &:focus,
  &:hover {
    // background: $account-layout-navigation-background-color;
    text-decoration: none;
    color: $color-primary;
  }

  &:after {
    content: $char-nbsp;
    height: 2px;
    display: block;
    background: transparent;
    border-radius: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.site-account-navigation-menu__link--active {
  background: $content-button-secondary-background;

  &:after {
    background: $color-primary;
  }
}

.site-account-navigation-menu__link-button {
  @include transition();

  border: 0;
  border-radius: radius(weak-to-strong);
  color: $content-color-typography;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  padding: stack() gutter(stronger);
  position: relative;
  text-decoration: none;
  text-align: center;

  &:hover {
    background: $content-button-secondary-background;
  }
}

.site-account-navigation-menu__link-button--active {
  color: $content-button-secondary-background;
}

.site-account-navigation__trigger {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  min-width: $header-height;
  padding: 0 gutter("layout");
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}

.site-account-navigation-menu__item--site {
  & + .site-account-navigation-menu__item--user {
    margin-top: stack(strongest) + stack(strongest);
  }
}

.site-account-navigation-menu__link-button--primary {
  background: $color-primary;
  border-color: $color-primary;
  color: $color-on-primary;

  &:hover {
    background: $button-hover-background;
    border-color: $button-hover-border-color;
    color: $button-hover-on-background;
  }
}

.site-account-navigation-menu__link-button--content-action {
  background: $content-button-action-background;
  border-color: $content-button-action-background;
  border-radius: radius(weak-to-strong);
  color: $content-button-action-on-background;

  &:hover {
    background: $content-button-action-background-hover;
    border-color: $content-button-action-background-hover;
    color: $content-button-action-on-background-hover;
  }
}

.site-account-navigation-menu__link-button--content-button {
  background: $content-button-background;
  border-color: $content-button-background;
  border-radius: radius(weak-to-strong);
  color: $content-button-on-background;

  &:hover {
    background: $content-button-background-hover;
    border-color: $content-button-background-hover;
    color: $content-button-on-background-hover;
  }
}

.site-account-navigation-menu__link-button--action {
  background: $color-primary;
  border-color: $color-primary;
  color: $color-on-primary;
  border-radius: radius(weak);
  padding: 6px gutter(strong);

  &:hover {
    background: $color-primary;
    border-color: $color-primary;
    color: #14424b;
  }
}

@include mq("medium-viewport") {
  .site-account-navigation {
    position: relative;
    margin-left: gutter(strongest) + gutter(strong);
  }

  .site-account-navigation__header {
    display: none;
  }

  .site-account-navigation__main {
    background: transparent;
    padding-top: 0;
    height: auto;
    position: static;
  }

  .site-account-navigation__main__content {
    padding: 0;
    height: auto;
  }

  .site-account-navigation-menu__collection {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  .site-account-navigation-menu {
    flex: 1;
  }

  .site-account-navigation-menu__item {
    & + .site-account-navigation-menu__item {
      margin-top: 0;
      margin-left: gutter(strongest);
    }
  }

  .site-account-navigation-menu__item--site {
    & + .site-account-navigation-menu__item--user {
      padding-left: gutter(strongest) + gutter(strong);
    }
  }

  .site-account-navigation-menu__item--site {
    & + .site-account-navigation-menu__item--site {
      margin-left: gutter(strong);
    }
  }

  .site-account-navigation-menu__link-button--action {
    padding: 12px gutter(stronger);
  }
}
