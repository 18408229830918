
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.icon__glyph--warning {
  fill: $color-warning;
}
