.article {
  display: flex;
  padding-top: stack(strongest);
  flex-direction: column;

  h2 {
    margin-top: stack(strongest);
  }

  h4 {
    font-weight: 500;
  }
}

.article__main {
  max-width: $max-main-width;
}

.article__aside {
  margin-top: 64px;
  width: 100%;
}

@include mq("medium-viewport") {
  .article {
    flex-direction: row;
  }

  .article__main {
    margin-right: gutter(strongest) + gutter(strongest);
  }

.article__aside {
  margin-left: auto;
  max-width: $max-aside-width;
}
}

.article__heading-counter {
  margin-right: gutter();

  &:after {
    content: $char-full-stop;
    color: $color-typography-meta;
  }
}