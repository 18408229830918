.faq__heading {
  font-weight: 500;
  margin: 0 0 stack();
}

.faq {
  & + .faq {
    margin-top: stack(strongest);
  }
}

.faq__main {
  color: $color-typography-meta;
}
