@import "../../variables/button";
@import "../../variables/_field.scss";

.button {
  @include transition();

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: $button-background;
  border: solid $button-border-width $button-border-color;
  border-radius: $button-radius;
  color: $button-color;
  cursor: pointer;
  display: inline-block;
  font-family: $button-typography-family;
  font-size: $button-typography-size;
  font-weight: $button-typography-weight;
  line-height: inherit;
  margin: 0;
  min-width: $touch-size;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:focus {
    box-shadow: 0 0 0px 4px $field-content-focus-color;
  }

  &:active,
  &:hover {
    background: $button-hover-background;
    border-color: $button-hover-border-color;
    color: $button-hover-on-background;
    text-decoration: none;

    .icon__glyph {
      fill: $button-hover-on-background;
    }
  }

  &.button--submit,
  &[type="submit"] {
    min-width: $button-action-width;
  }

  &[aria-disabled="true"]:not(.button--pending),
  &[disabled]:not(.button--pending) {
    cursor: default;
    opacity: 0.32;
    pointer-events: none;
  }

  .icon__glyph {
    fill: $button-color;
  }

  .pending {
    &:before {
      border-left-color: $button-color;
    }
  }

  .button__icon {
    margin-right: gutter();
  }
}

.button--submit {
  min-width: $button-action-width;
}

.button__main {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $button-adjust-the-total-button-space gutter("strong");
}

/**
 * 1. Stop the button looking clickable.
 * 2. Stops hover effects and will prevent iOS click events ONLY.
 */
.button--pending {
  cursor: default;
  /* 1 */
  pointer-events: none;
  /* 2 */

  &:active,
  &:focus,
  &:hover {
    background: $button-background;
    border-color: $button-border-color;
    color: $button-color;
  }

  .button__content {
    visibility: hidden;
  }
}

.button__content {
  display: flex;
  justify-content: center;
  flex: 1;
}

.button__pending {
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.button__pending__content {
  margin-right: gutter();
}

.button__pending__icon {
  height: $button-pending-size;

  &:before {
    border-width: 4px;
    border-color: rgba($button-pending-color, $button-pending-bar-opacity);
    border-left-color: $button-pending-color;
    height: $button-pending-size;
    width: $button-pending-size;
  }

  &:after {
    height: $button-pending-size;
    width: $button-pending-size;
  }
}

.button--back {
  border-color: transparent;
  background: none;
  color: inherit;
  min-width: 0;

  .button__main {
    padding-left: 0;
    padding-right: gutter(weak);
  }

  .icon__glyph {
    fill: $color-typography;
  }

  &:hover {
    background: none;
    border-color: transparent;
    color: $color-primary;

    .icon__glyph {
      fill: $color-primary;
    }
  }

  &:focus {
    border-color: #fff;
    box-shadow: 0 0 0px 4px $field-content-focus-color;
    background: none;
    color: $color-typography;

    .icon__glyph {
      fill: $color-typography;
    }
  }
}

.button__icon-suffix,
.button__icon-prefix {
  display: flex;
  flex: 0 1;
  align-items: center;

  &:empty {
    display: none;
  }
}

.button__icon-suffix {
  margin-left: gutter();
  margin-right: negative(gutter());
}

.button__icon-prefix {
  margin-right: gutter();
  margin-left: negative(gutter());
}

.button--block {
  width: 100%;
}

.button--secondary {
  border-color: $button-secondary-background-color;
  background: $button-secondary-background-color;
  color: $color-typography;
  min-width: 0;

  .icon__glyph {
    fill: $color-typography;
  }

  &:hover {
    background: #ededed;
    border-color: #ededed;
    color: $color-typography;

    .icon__glyph {
      fill: $color-typography;
    }
  }

  &:focus {
    box-shadow: 0 0 0px 4px $field-content-focus-color;

    .icon__glyph {
      fill: $color-typography;
    }
  }

  .button__pending__icon {
    &:before {
      border-color: rgba($color-typography, $button-pending-bar-opacity);
      border-left-color: $color-typography;
    }
  }
}

.button--tertiary {
  border-color: #f2f2f2;
  background: none;
  color: inherit;
  min-width: 0;

  .icon__glyph {
    fill: $color-typography;
  }

  &:hover {
    background: none;
    border-color: $color-primary;
    color: $color-typography;

    .icon__glyph {
      fill: $color-typography;
    }
  }

  &:focus {
    box-shadow: 0 0 0px 4px $field-content-focus-color;

    .icon__glyph {
      fill: $color-typography;
    }
  }
}

.button--quaternary {
  border-color: transparent;
  background: transparent;
  color: inherit;
  min-width: 0;

  .icon__glyph {
    fill: $color-typography;
  }

  &:hover {
    background: none;
    border-color: transparent;
    color: $color-primary;

    .icon__glyph {
      fill: $color-primary;
    }
  }

  &:focus {
    box-shadow: 0 0 0px 4px $field-content-focus-color;

    .icon__glyph {
      fill: $color-primary;
    }
  }
}

.button--weak {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  .button__main {
    padding: 6px gutter(default-strong);
  }

  .icon__canvas {
    height: auto;
    width: 20px;
  }

  .button__icon-prefix {
    margin-left: 0;
  }
}

.button--weaker {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  .button__main {
    padding: 2px gutter();
  }

  .icon__canvas {
    height: auto;
    width: 20px;
  }
}

.button--weakest {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  .button__main {
    padding: 0;
  }

  .icon__canvas {
    height: auto;
    width: 20px;
  }
}

.button--justify-start {
  .button__main {
    justify-content: flex-start;
  }
}

.button--icon-only {
  .button__main {
    padding-left: 10px;
    padding-right: 10px;
  }

  .button__icon-prefix,
  .button__icon-suffix {
    margin-left: 0;
    margin-right: 0;
  }

  .button__label {
    @include visually-hidden();
  }
}

@include mq("medium-viewport") {
  .button\@medium--inline {
    width: auto;
  }
}

.button__subtitle {
  font-size: typography-size("small");
  font-weight: typography-weight("small");
  line-height: typography-line-height("small");
  color: $color-typography-meta;
  margin-top: stack();

  .icon__glyph {
    fill: $color-typography-meta;
  }
}

.button--anchor {
  all: inherit;
  text-decoration: underline;
  display: inline;
  cursor: pointer;
  color: $color-typography;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;

  &:hover {
    background: none;
    text-decoration: none;
    color: $color-typography;

    .icon__glyph {
      fill: $color-typography;
    }
  }

  .button__main {
    padding: 0;
  }

  .icon {
    vertical-align: baseline;
  }

  .icon__canvas {
    height: auto;
    width: 16px;
    vertical-align: middle;
  }

  .button__icon-prefix {
    margin-right: gutter(weak);
  }

  .button__icon-suffix {
    margin-left: gutter(weak);
  }

  .icon__glyph {
    fill: $color-typography;
  }

  .button__icon-prefix {
    margin-left: 0;
  }

  .button__icon-suffix {
    margin-right: 0;
  }
}

.button--notification {
  .button__main {
    position: relative;

    &:after {
      $notification-border-width: 2px;
      $notification-size: 8px;

      content: $char-nbsp;
      background: $color-info;
      display: block;
      width: $notification-size + $notification-border-width * 2;
      height: $notification-size + $notification-border-width * 2;
      border-radius: 50%;
      position: absolute;
      top: stack();
      left: gutter();
      border: solid $notification-border-width #fff;
      z-index: elevation("high");
    }
  }
}

.button--roundal {
  border-radius: 24px;
  border-color: transparent;

  &:hover {
    border-color: $button-secondary-background-color;
    background: $button-secondary-background-color;
  }

  &:not(.button--weakest) .button__main {
    padding: 10px;
  }

  &.button--weakest .button__main {
    padding: 0 2px;
  }

  &.button--weak {
    .button__main {
      padding: 6px 8px;
    }
  }

  .icon {
    vertical-align: middle;
  }
}

.button--destructive {
  background: $color-danger;
  border-color: $color-danger;
  color: $color-on-danger;

  &:hover {
    background: $color-danger-background;
    border-color: $color-danger-background;
    color: $color-danger;

    .icon__glyph {
      fill: $color-danger;
    }
  }

  .icon__glyph {
    fill: $color-on-danger;
  }
}
