@use "sass:math";

$space: 8px;
$stack: 8px;
$gutter: 8px;
$radius: 8px;

/**
 * The minimum touch size for mobile usability.
 */
$touch-size: 44px;

/**
 * This is the maximum site width for content. Unless a
 * design uses a full screen width, use this to constrain
 * the size of the screen to make it usable.
 */
$max-width: 960px;

$layout-max-width: 1200px;

$max-main-width: 660px;

$max-landing-width: 736px;

$max-form-width: 480px;

$max-aside-width: 320px;

$max-content-width: 580px;

$header-height: 64px;

$button-action-width: 224px;

$site-gutter: 16px;

// Hubspot/Intercom floating button.
$safe-area-inset-bottom: 90px;

$gutters: (
  small-viewport: (
    layout: $gutter * 2,
    weakest: math.div($gutter, 4),
    weaker: math.div($gutter, 3),
    weak: math.div($gutter, 2),
    default: $gutter,
    mid: $gutter * 1.5,
    default-strong: $gutter * 1.5,
    strong: $gutter * 2,
    mid-strong: $gutter * 2.5,
    strong-stronger: $gutter * 2.5,
    stronger: $gutter * 3,
    mid-stronger: $gutter * 3.5,
    stronger-strongest: $gutter * 3.5,
    strongest: $gutter * 4,
  ),
  medium-viewport: (
    layout: $gutter * 2,
    weakest: math.div($gutter, 3),
    weaker: math.div($gutter, 2),
    weak: $gutter,
    default: $gutter * 2,
    mid: $gutter * 2.5,
    default-strong: $gutter * 2.5,
    strong: $gutter * 3,
    mid-strong: $gutter * 3.5,
    strong-stronger: $gutter * 3.5,
    stronger: $gutter * 4,
    mid-stronger: $gutter * 4.5,
    stronger-strongest: $gutter * 4.5,
    strongest: $gutter * 5,
  ),
);

$stacks: (
  small-viewport: (
    buffer: $gutter * 4,
    layout: $gutter * 2,
    weakest: math.div($stack, 4),
    weaker: math.div($stack, 3),
    weak: math.div($stack, 2),
    default: $stack,
    mid: $stack * 1.5,
    default-strong: $stack * 1.5,
    strong: $stack * 2,
    mid-strong: $stack * 2.5,
    strong-stronger: $stack * 2.5,
    stronger: $stack * 3,
    mid-stronger: $stack * 3.5,
    stronger-strongest: $stack * 3.5,
    strongest: $stack * 4,
  ),
  medium-viewport: (
    buffer: $gutter * 4,
    layout: $gutter * 2,
    weakest: math.div($stack, 3),
    weaker: math.div($stack, 2),
    weak: $stack,
    default: $stack * 2,
    mid: $stack * 2.5,
    default-strong: $stack * 2.5,
    strong: $stack * 3,
    mid-strong: $stack * 3.5,
    strong-stronger: $stack * 3.5,
    stronger: $stack * 4,
    mid-stronger: $stack * 4.5,
    stronger-strongest: $stack * 4.5,
    strongest: $stack * 5,
  ),
);

/**
 * The radius size for a given size. At the moment they
 * are the same. See the function radius().
 */
$radii: (
  small-viewport: (
    default: $radius,
    weak: math.div($radius, 2),
    // 4px
    mid: $radius * 1.5,
    // 12px
    weak-to-strong: $radius * 1.5,
    // 12px
    strong: $radius * 2,
    // 16px
    mid-strong: $radius * 2.5,
    // 20px
    strong-to-stronger: $radius * 2.5,
    // 20px
    stronger: $radius * 3,
    // 24px
    mid-stronger: $radius * 3.5,
    strongest: $radius * 4,
    inside-mid: 10px,
  ),
  medium-viewport: (
    default: $radius,
    weak: math.div($radius, 2),
    mid: $radius * 1.5,
    strong: $radius * 2,
    mid-strong: $radius * 2.5,
    stronger: $radius * 3,
    mid-stronger: $radius * 3.5,
    strongest: $radius * 4,
    inside-mid: 10px,
  ),
);

/**
 * Z-index control to limit the layer guessing game.
 */
$elevation: (
  high: 1,
  higher: 10,
  highest: 20,
  max: 2147483647,
);

$one-third: 33.33333%;
