
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

@import "../assets/styles/index.scss";

$site-header-height: (
  "small-viewport": $header-height,
  "medium-viewport": $header-height,
);

$site-max-width: (
  "medium-viewport": $max-width,
);

.site {
  margin: 0 auto;
  max-width: map-get($site-max-width, "medium-viewport");
}

.site-header {
  transform: translateZ(0);
  background: #fff;
  display: flex;
  height: map-get($site-header-height, "small-viewport");
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.site--modal {
  .site-main {
    margin-top: stack(strongest);
  }
}

.site-header__content {
  position: relative;
  margin: 0 auto;
  max-width: $max-width;
  width: 100%;
}

.site-logo {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  .site-account-logo__graphic {
    height: 40px;
    width: auto;
  }
}

.site-main {
  min-height: 100vh;
  padding-top: map-get($site-header-height, "small-viewport");
}

.site-main__content--modal {
  display: flex;
  flex-direction: column;
}
