@mixin mq($from: "") {
  // Default variable set to test for the application
  // of a valid query.
  $media-query: "";

  @if map-has-key($breakpoints, $from) {
    $media-query: "(min-width: #{map-get($breakpoints, $from)})";
  }

  @if $media-query not "" {
    @media #{$media-query} {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin visually-hidden() {
  border: 0;
  clip-path: inset(50%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

@mixin visually-unhidden() {
  border: initial;
  clip-path: initial;
  clip: initial;
  height: initial;
  margin: initial;
  overflow: initial;
  padding: initial;
  position: initial;
  width: initial;
  word-wrap: initial;
}