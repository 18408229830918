
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.tag {
  align-self: center;
  background: rgba($color-typography, 0.08);
  color: $color-typography;
  align-items: center;
  border-radius: radius(weak);
  display: inline-flex;
  font-size: typography-size("meta");
  font-weight: typography-weight("meta");
  line-height: typography-line-height("meta");
  padding: 1px gutter();

  .icon__canvas {
    height: auto;
    width: 16px;
  }
}

.tag__icon {
  display: inline-flex;
  margin-right: gutter(weak);
}

.tag__label {
  display: inline-flex;
}

.tag--primary {
  background: $color-primary;
  color: $color-on-primary;
}

.tag--success {
  background: $color-success;
  color: $color-on-success;

  .icon__glyph {
    fill: $color-on-success;
  }
}

.tag--success.tag--background {
  background: #c4ebdf;
  color: inherit;

  .icon__glyph {
    fill: inherit;
  }
}

.tag--warning {
  background: $color-warning;
  color: $color-on-warning;

  .icon__glyph {
    fill: $color-on-warning;
  }
}

.tag--danger {
  background: $color-danger;
  color: $color-on-danger;

  .icon__glyph {
    fill: $color-on-danger;
  }
}

.tag--danger.tag--background {
  background: #f8eff0;
  color: inherit;

  .icon__glyph {
    fill: inherit;
  }
}

.tag--suggestion {
  background: $color-info-background;
  color: $color-info;

  .icon__glyph {
    fill: $color-info;
  }
}

.tag--pending {
  background: $color-help;
  color: $color-on-help;

  .icon__glyph {
    fill: $color-on-help;
  }
}

.tag--nowrap {
  white-space: nowrap;
}

.tag--gutter {
  margin-left: gutter();
  margin-right: gutter();
}
