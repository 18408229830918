
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.button-group {
  display: flex;
  margin: negative(stack("layout")) 0 stack("layout") negative(gutter("layout"));
  flex-wrap: wrap;

  > .button,
  .button-group__item,
  > .link-button {
    margin: stack("layout") 0 0 gutter("layout");
  }
}

.button-group__item {
  display: flex;
  flex-direction: column;
  // flex: 1;
}

.button-group__item-meta {
  margin-top: stack();
  text-align: center;
}

.button-group--column {
  flex-direction: column;
}

.button-group--row {
  flex-direction: row;
  flex-wrap: unset;
}

.button-group--centered {
  align-items: center;
  justify-content: center;
}

.button-group--traversal {
  flex-direction: column;
  justify-content: space-between;
  max-width: $max-content-width + gutter(strong);
}

.button-group--navigation {
  flex-direction: column;
  justify-content: space-between;
}

.button-group__label {
  display: block;
  font-weight: 500;
  margin: 0 0 stack();
}

.field-item {
  & > .button-group {
    margin-bottom: 0;
  }
}

@include mq("medium-viewport") {
  .button-group--traversal {
    flex-direction: row;
    align-items: flex-start;

    .button-group__item {
      flex: unset;
    }
  }

  .button-group__item-meta {
    text-align: initial;

    .button-group__item {
      flex: unset;
    }
  }

  .button-group--navigation {
    flex-direction: row;
    align-items: flex-start;

    .button-group__item {
      flex: unset;
    }
  }
}
