.roundel-list {
  list-style: none;
  padding: 0;
  max-width: $max-content-width;
  margin-bottom: stack(strongest) + stack(strongest);
}

.roundel-list__item {
  display: flex;

  & +  .roundel-list__item {
    margin-top: stack(strongest);
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.roundel-list__heading {
  font-weight: 600;
  margin: 0;
}

.roundel-list__heading--strong {
  font-size: typography-size("heading-4");
  line-height: typography-line-height("heading-4");
}

.roundel-list__description {
  color: $color-typography-meta;
}

.roundel-list__item__roundel {
  background: #01bc80;
  display: flex;
  border-radius: 50%;
  flex: 0 0 32px;
  margin: 0 gutter() 0 0;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  .icon__canvas {
    height: 20px;
    width: 20px;
  }

  .icon__glyph {
    fill: #fff;
  }
}

.roundel-list__item__roundel--strong {
  flex: 0 0 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;

  .icon__canvas {
    height: 16px;
    width: 16px;
  }
}

.roundel-list__item__roundel--stronger {
  flex: 0 0 48px;
  height: 48px;
  margin-right: gutter(strong);
  min-height: 48px;
  width: 48px;

  .icon__canvas {
    height: 28px;
    width: 28px;
  }
}

.roundel-list__item__roundel--on-icon-fill {
  background-color: $color-icon-fill;

  .icon__canvas {
    fill: #fff;
  }
}