$button-border-width: 2px;
$button-typography-family: inherit;
$button-typography-size: 18px;
$button-typography-weight: 600;
$button-border-color: $color-primary;
$button-background: $color-primary;
$button-color: $color-on-primary;
$button-radius: 4px;
$button-focus-border-width: 4px;

$button-hover-border-color: #009A5B;
$button-hover-background: #009A5B;
$button-hover-on-background: #FFF;

$button-pending-size: 28px;
$button-pending-bar-opacity: 0.25;
$button-pending-border-size: 4px;
$button-pending-color: $color-on-secondary-800;
$button-pending-speed: 600ms;
$button-pending-size: 24px;

$button-adjust-the-total-button-space: stack() + 2px;

$button-minor-background-color: $color-secondary-10;
$button-minor-border-color: $color-secondary-10;
$button-minor-color: $color-typography;
$button-minor-pending-color: $color-secondary-800;

$button-minor-hover-background-color: $color-secondary-100;
$button-minor-hover-border-color: $color-secondary-100;


$button-secondary-background-color: #f8f8f8;