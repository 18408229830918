$char-nbsp: "\00a0";
$char-comma: "\002C";
$char-open-quote: "\201c";
$char-close-quote: "\201d";
$char-en-dash: "\2013";
$char-em-dash: "\2014";
$char-back: "\2039";
$char-forward: "\203A";
$char-full-stop: "\002E";
$char-ellipsis: "\2026";
$char-bullet: "\2022";