
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

@import "../../../assets/styles/variables/_field.scss";

$input-toggle-width: 40px;

.input-toggle__option {
  align-items: center;
  display: flex;
  margin: stack() 0 0 0;
  position: relative;
  width: fit-content;
}

.input-toggle__option__input {
  left: -9999em;
  position: absolute;

  &:checked {
    & + .input-toggle__option__signifier {
      background: $color-primary;
      border-color: $color-primary;

      .input-toggle__option__signifier__icon {
        left: 16px;

        .input-toggle__option__signifier__icon__glyph {
          opacity: 1;
        }
      }
    }
  }
}

.input-toggle__option__signifier {
  background: $field-content-border-color;
  border-radius: 12px;
  border: $field-content-border;
  display: block;
  height: 24px;
  margin-right: gutter();
  margin-bottom: auto;
  min-width: 40px;
  width: $input-toggle-width;
  cursor: pointer;
}

.input-toggle__option__signifier__icon {
  @include transition();

  background: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  border-radius: 10px;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  vertical-align: bottom;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-toggle__option__signifier__icon__canvas {
  height: 16px;
  width: 16px;
}

.input-toggle__option__signifier__icon__glyph {
  @include transition();
  opacity: 0;
  fill: $color-secondary-800;
}

.field-input-toggle--invalid {
  .input-toggle__option__signifier {
    border-color: $color-danger;
  }
}

.input-toggle__option__label {
  font-weight: 500;
}

.field-input-toggle__description {
  margin-left: $input-toggle-width + gutter();
  margin-right: gutter();
}

.input-toggle__option + .field-input-toggle__description {
  margin-top: stack();
}

.input-toggle__option__input {
  &:focus {
    & + .input-toggle__option__signifier {
      box-shadow: 0 0 0 $button-focus-border-width $field-content-focus-color;
    }
  }
}
