$pending-default-size: 48px;
$pending-default-border-size: 6px;
$pending-animation-speed: 500ms;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pending {
  display: block;
  height: $pending-default-size;

  &:before {
    animation: rotate $pending-animation-speed infinite linear;
    border: $pending-default-border-size solid rgba($color-primary, 0.08);
    border-left: $pending-default-border-size solid $color-primary;
    border-radius: 50%;
    content: $char-nbsp;
    display: block;
    height: $pending-default-size;
    width: $pending-default-size;
  }

  &:after {
    border-radius: 50%;
    content: $char-nbsp;
    display: block;
    height: $pending-default-size;
    width: $pending-default-size;
  }
}

.pending--centered {
  align-self: center;
}

.pending--weak {
  $pending-weak-size: 32px;
  $pending-weak-border-size: 4px;
  height: $pending-weak-size;

  &:before {
    border-width: $pending-weak-border-size;
    height: $pending-weak-size;
    width: $pending-weak-size;
  }

  &:after {
    height: $pending-weak-size;
    width: $pending-weak-size;
  }
}

.pending--weakest {
  $pending-weakest-size: 24px;
  $pending-weakest-border-size: 4px;
  height: $pending-weakest-size;

  &:before {
    border-width: $pending-weakest-border-size;
    height: $pending-weakest-size;
    width: $pending-weakest-size;
  }

  &:after {
    height: $pending-weakest-size;
    width: $pending-weakest-size;
  }
}

.pending--strong {
  $pending-strong-size: 56px;
  $pending-strong-border-size: 6px;

  height: $pending-strong-size;

  &:before {
    border-width: $pending-strong-border-size;
    height: $pending-strong-size;
    width: $pending-strong-size;
  }

  &:after {
    height: $pending-strong-size;
    width: $pending-strong-size;
  }
}

.pending--weak-icon {
  $pending-weakest-size: 16px;
  $pending-weakest-border-size: 3px;
  height: $pending-weakest-size;

  &:before {
    border-width: $pending-weakest-border-size;
    height: $pending-weakest-size;
    width: $pending-weakest-size;
  }

  &:after {
    height: $pending-weakest-size;
    width: $pending-weakest-size;
  }
}
