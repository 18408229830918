
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

/**
 *----------------------------------------
  VARIABLES
 *----------------------------------------
 */
@import "../assets/styles/variables/button";

$account-layout-max-width: $layout-max-width;
$account-layout-header-width: 280px;
$account-layout-content-width: $layout-max-width;

/**
 *----------------------------------------
  CONTAINER
 *----------------------------------------
 */

@include mq("medium-viewport") {
  .site-account {
    padding: 0 $site-gutter;
  }
}

/**
 *----------------------------------------
  MAIN
 *----------------------------------------
 */

.site-account-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding: $header-height + stack() $site-gutter 0;
}

.site-account-main__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  position: relative;
}

@include mq("medium-viewport") {
  .site-account-main {
    min-height: 100vh;
  }

  .site-account-main__content {
    margin: auto;
    max-width: $account-layout-content-width;
    width: 100%;
  }
}

@include mq("medium-viewport") {
  .site-account-main {
    padding: 0;
  }
}

.site-account--pull {
  margin-left: -24px;
  margin-right: -24px;
}
