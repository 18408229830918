.button-link {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  text-align: left;
  font-family: inherit;
  line-height: inherit;
  cursor: pointer;
  font-weight: inherit;
}

.button-link__content {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}