.numbered-list {
  list-style: none;
  padding: 0;
  margin: 0 0 stack(strongest);
  display: flex;
  flex-direction: column;
  counter-reset: item;
}

.numbered-list__item {
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  position: relative;

  & + .numbered-list__item {
    margin-top: stack(strong);

    p:last-child {
      margin-bottom: 0;
    }
  }

  &::before {
    content: counter(item);
    counter-increment: item;
    background-color: $content-panel-900;
    color: $content-on-panel-900;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.numbered-list__item__heading {
  font-weight: 600;
  margin: 0;
}

.numbered-list__item__description {
  color: $color-typography-meta;
}
