.content {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.content__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: stack(strongest);

  &+.content__aside {
    margin-top: stack(strongest) + stack();
  }
}
  
.content__aside {
  max-width: $max-content-width;
}

@include mq("medium-viewport") {
  .content {
    flex-direction: row;
    justify-content: space-between;
  }

  .content__main {
    margin-right: gutter(strongest);
    margin-bottom: 0
  }

  .content--modal {
    display: flex;
    justify-content: center;
    margin-top: stack();

    .content__main {
      max-width: $max-content-width;
      // max-width: $max-main-width;
      margin-right: 0;
    }
  }
  
  .content__aside {
    min-width: $max-aside-width;
    max-width: $max-aside-width;
  }
}