
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.icon {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  vertical-align: bottom;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon__glyph {
  fill: #022d35;
}

.icon--is-danger {
  .icon__glyph {
    fill: $color-danger;
  }
}

.icon--is-warning {
  .icon__glyph {
    fill: $color-warning;
  }
}

.icon--is-success {
  .icon__glyph {
    fill: $color-success;
  }
}

.icon--meta {
  .icon__glyph {
    fill: $color-typography-meta;
  }
}
