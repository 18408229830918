
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.icon--menu {
  width: 18px;

  &:after,
  &:before,
  & span {
    background-color: $color-secondary-900;
    border-radius: 2px;
    content: $char-nbsp;
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: transform $transition-duration ease-in-out;
  }
}

.icon-menu--open {
  &:before {
    transform: translateY(8px) rotate(135deg);
  }

  &:after {
    transform: translateY(-4px) rotate(-135deg);
  }

  span {
    transform: scale(0);
  }
}
