/**
  * This allows JavaScript to detect the breakpoint.
  * This keeps the responsibility of the breakpoints
  * with the CSS and a separation of concerns.
  */
body {
  &:before {
    content: "small-viewport";
    display: none;
    visibility: hidden;
  }
}

@include mq("medium-viewport") {
  body {
    &:before {
      content: "medium-viewport";
    }
  }
}

@include mq("large-viewport") {
  body {
    &:before {
      content: "large-viewport";
    }
  }
}