$field-stack: stack(strongest);
$field-gutter: stack(strongest);
$field-max-width: 488px;
$field-content-background: #fff;
$field-content-border-color: #e9e9e9;
$field-content-border-width: 2px;
$field-content-border: solid $field-content-border-width
  $field-content-border-color;
$field-content-radius: 4px;
$field-label-weight: 500;
$field-content-focus-color: rgba($color-primary, 0.16);
$field-disabled-opacity: 0.62;
$prefix-border-radius: $field-content-radius - $field-content-border-width;
$field-placeholder-color: #e9e9e9;
