@import "../../variables/field";

.field__header {
  align-items: center;
  display: flex;
  margin-bottom: stack();
}

.field__label {
  align-items: center;
  display: flex;
  font-weight: $field-label-weight;
  // margin-bottom: stack();
}

.field__main__content {
  @include transition();
  // align-items: center;
  align-items: stretch;
  background: $field-content-background;
  border: $field-content-border;
  border-radius: $field-content-radius;
  display: flex;
  position: relative;

  &:focus-within {
    box-shadow: 0 0 0 $button-focus-border-width $field-content-focus-color;
  }
}

.field__main__content--smaller {
  max-width: 50%;
}

.field__input {
  background: transparent;
  border: 0;
  border-radius: $field-content-radius - $field-content-border-width;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;

  &::placeholder {
    color: rgba($color-typography, 0.56);
    font-size: inherit;
    line-height: inherit;
  }
}

.field-input--invalid {
  .field__main__content {
    border-color: $color-danger;

    &:focus-within {
      box-shadow: 0 0 0 $button-focus-border-width $color-danger-background;
    }
  }
}

.field__optional-label {
  font-weight: 400;
}

.field__description {
  color: $color-typography-meta;
}

.field-input--disabled {
  .field__input {
    opacity: $field-disabled-opacity;
  }
}

.field__main__content__fix {
  align-items: center;
  background: rgba(#e9e9e9, 0.4);
  display: flex;
  font-weight: 500;
  padding: 0 gutter(strong);
  white-space: nowrap;
}

.field__main__content__fix--prefix {
  & + .field__input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.field__main__content__prefix {
  border-bottom-left-radius: $prefix-border-radius;
  border-top-left-radius: $prefix-border-radius;
}

.field__main__content__suffix {
  border-bottom-right-radius: $prefix-border-radius;
  border-top-right-radius: $prefix-border-radius;
}

.field__main__content__fix--pending {
  background-color: transparent;
}

.field-input--year,
.field-input--postcode,
.field-input--currency {
  .field__main {
    max-width: 224px;
  }
}

.field-input--percentage {
  .field__main {
    max-width: 120px;
  }
}

.field-input--highlight {
  .field__main {
    border-radius: 4px;
    background: $color-secondary-10;
    padding: stack() gutter();
  }
}

.field-input__aside {
  background: color-secondary-10;
  border-bottom-left-radius: radius(strong);
  border-bottom-right-radius: radius(strong);
  padding: stack() gutter(strong) + $field-content-border-width stack(strong);
  box-shadow: 0 negative($field-content-radius) 0 color-secondary-10;
}

.field-form__heading {
  font-family: content-typography-font-family("heading-0");
}

.field-form__heading--1 {
  font-size: content-typography-size("heading-3");
  line-height: content-typography-line-height("heading-3");
  font-weight: content-typography-weight("heading-3");
  margin: stack(strongest) * 2 0 stack();
}

.field-input--weak {
  .field__input--text,
  .field__input--select {
    padding-bottom: 6px;
    padding-top: 6px;
  }
}

.field__footer {
  margin: stack() 0 0;
}
