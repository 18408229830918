 .grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.grid--row {
  flex-direction: row;
}

.grid-item {
  flex: 1;
}

@include mq("medium-viewport") {

  .grid\@medium--row {
    flex-direction: row;
  }

  .grid\@medium--gutter {
    margin: -32px 0 0 -32px;

    .grid-item {
      margin: 32px 0 0 32px;
    }
  }

}
