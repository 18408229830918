
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

@import "../assets/styles/index.scss";
@import "../assets/styles/back-office";
@import "../assets/styles/variables/back-office";

$back-office-site-header-desktop-width: 240px;
$back-office-site-header-mobile-height: 64px;
$back-office-site-navigation-main-width: 320px;

.back-office-site {
  background: #fafcff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.back-office-site-header {
  background: #101740;
  transform: translateZ(0);
  display: flex;
  height: $back-office-site-header-mobile-height;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 300;
}

.back-office-site-header__content {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.back-office-site-logo {
  height: 32px;
  width: auto;
  display: block;
  left: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.back-office-site-logo__graphic {
  height: 32px;
  width: auto;
  display: block;
}

.back-office-site-navigation {
  display: flex;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  z-index: 1;
}

.back-office-site-navigation--active {
  z-index: 10;

  .back-office-site-navigation__main {
    right: 0;
  }
}

.back-office-site-navigation__header {
  align-items: center;
  display: flex;
  min-height: $back-office-site-header-mobile-height;
  position: relative;
}

.back-office-site-navigation__trigger {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  min-width: $back-office-site-header-mobile-height;
  padding: 0 16px;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;

  .icon--menu {
    &:after,
    &:before,
    & span {
      background-color: #fff;
    }
  }
}

.back-office-site-navigation__main {
  transition: right 0.1s ease;
  background: #101740;
  height: 100vh;
  right: negative($back-office-site-navigation-main-width);
  padding-top: $back-office-site-header-mobile-height;
  position: absolute;
  top: 0;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  width: 100%;
  max-width: $back-office-site-navigation-main-width;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.back-office-site-main {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: $back-office-site-header-mobile-height 0 0;
}

/**
 * -----------------------------------------------------------------------------
 * Menu
 * -----------------------------------------------------------------------------
*/

.back-office-site-navigation-menu {
  list-style: none;
  margin: 0 0 stack();
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.back-office-site-navigation-menu__item {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.back-office-site-navigation-menu__icon {
  margin-right: gutter();

  .icon__glyph {
    fill: #fff;
  }
}

.back-office-site-navigation-menu__anchor {
  @include transition(background);
  color: #fff;
  text-decoration: none;
  display: flex;
  position: relative;
  font-weight: 600;
  align-items: center;
  padding: 11px 12px;
  font-size: 18px;
  line-height: 26px;
  background: transparent;
  border-radius: radius(weak);
  margin: 0 gutter();

  &:hover {
    background: rgba(#fff, 0.16);
  }
}

.back-office-site-navigation-menu__anchor--active {
  background: rgba(#fff, 0.16);

  .back-office-site-navigation-menu__icon {
    .icon__glyph {
      fill: $color-primary;
    }
  }
}

.back-office-site-navigation-menu__heading {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  margin: 32px 0 0 24px;
  color: #fff;
}

.back-office-site-navigation-menu__disclosure {
  all: inherit;
  @include transition(background);
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  padding: 11px 12px;
  font-size: 18px;
  line-height: 26px;
  background: transparent;
  border-radius: radius(weak);
  margin: 0 gutter();

  &:hover {
    background: rgba(#fff, 0.16);
  }
}

.back-office-site-navigation-menu__disclosure--active {
  background: rgba(#fff, 0.16);
}

.back-office-site-navigation-menu__disclosure__label {
  flex: 1;
}

.back-office-site-navigation-menu__disclosure-icon {
  .icon__glyph {
    fill: #fff;
  }
}

.back-office-site-navigation-sub-menu {
  $sub-menu-stroke-width: 2px;

  color: #fff;
  list-style: none;
  margin: 0 8px 4px 32px;
  padding: 0 0 0 12px - $sub-menu-stroke-width;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: $sub-menu-stroke-width;
    height: 100%;
    background: rgba(#fff, 0.16);
    border-radius: 1px;
  }
}

.back-office-site-navigation-sub-menu__anchor {
  text-decoration: none;
  display: block;
  padding: 11px 12px;
  border-radius: radius(weak);
  font-weight: 500;

  &:hover {
    background: rgba(#fff, 0.16);
  }
}

.back-office-site-navigation-sub-menu__anchor--active {
  background: rgba(#fff, 0.16);
}

.back-office-site-account {
  background: rgba(#fff, 0.16);
  border-radius: radius(weak);
  margin: 0 gutter() stack() gutter();
  padding: stack() gutter();
}

.back-office-site-account__header {
  align-items: center;
  display: flex;
  margin: 0 0 stack(strong);
}

.back-office-site-account__avatar {
  margin-right: gutter();
}

.back-office-site-account__organisation-name {
  color: #fff;
  display: block;
  font-weight: 600;
  margin-bottom: stack(weak);
  word-break: break-word;
}

.back-office-site-account__user-name {
  color: #fff;
  display: block;
  font-size: typography-size("meta");
  font-weight: 400;
  line-height: typography-size("meta");
}

.back-office-site-account .back-office-site-account__button {
  .button__content {
    justify-content: flex-start;
  }
}

@include mq("large-viewport") {
  .back-office-site {
    flex-direction: row;
  }

  .back-office-site-header {
    height: 100%;
    position: fixed;
    width: $back-office-site-header-desktop-width;
  }

  .back-office-site-header__content {
    flex-direction: column;
    flex: 1;
    max-height: 100vh;
  }

  .back-office-site-logo {
    width: auto;
    position: static;
    transform: none;
    margin: 32px 16px 64px;
  }

  .back-office-site-logo__graphic {
    height: 40px;
  }

  .back-office-site-navigation {
    flex: 1;
  }

  .back-office-site-navigation__header {
    display: none;
  }

  .back-office-site-navigation {
    position: static;
  }

  .back-office-site-navigation__main {
    position: static;
    padding-top: 0;
    height: auto;
  }

  .back-office-site-main {
    display: flex;
    flex: 1;
    margin-left: $back-office-site-header-desktop-width;
    margin-bottom: 90px;
    padding: 0;
  }
}

// .back-office-site {
//   background: #fafcff;
//   display: flex;
//   flex-direction: row;
//   min-height: 100vh;
// }

// .back-office-site-header {
//   background: #101740;
//   display: flex;
//   height: 100%;
//   position: fixed;
//   transform: translate3d(0, 0, 0);
//   width: 100%;
//   width: $back-office-site-header-width;
//   z-index: 300;
// }

// .back-office-site-header__content {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   max-height: 100vh;
// }

// .back-office-site-logo {
//   display: flex;
//   justify-content: center;
//   margin: stack(strongest) 0 stack(strongest) * 2;
//   z-index: 10;
// }

// .back-office-site-logo--strong {
//   .back-office-site-logo__graphic {
//     height: 56px;
//   }
// }

// .back-office-site-logo__graphic {
//   height: 40px;
//   width: auto;
// }

// .back-office-site-main {
//   display: flex;
//   flex: 1;
//   margin-left: $back-office-site-header-width;
//   margin-bottom: $safe-area-inset-bottom;
// }

// .back-office-site-main__content {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
// }

// .back-office-content {
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// }

.back-office-content__title {
  margin: 0;
}

.back-office-content__subtitle {
  margin: stack() 0 0;
}

.back-office__affiliate-logo {
  height: 24px;
  width: auto;
}

.back-office-content__main {
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: solid 2px #e9e9e9;
  padding: stack(strongest) gutter(strongest);
}

.back-office-content--modal {
  .back-office-content__main {
    padding: 0;
    margin: 0;
    background: transparent;
    flex: 1;
    border-top: none;
  }
}

.back-office-content--tabs {
  margin-bottom: negative($safe-area-inset-bottom);

  .back-office-content__main {
    padding: 0;
    border: 0;
    background: transparent;
    flex: 1 1 100%;
  }
  .back-office-content__header {
    padding-bottom: stack(strong);
  }

  .back-office-tabs {
    .tabs-section {
      padding-bottom: $safe-area-inset-bottom;
    }
  }
}

.back-office-tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  .tabs-nav {
    padding: 0 gutter(strong);

    @include mq("large-viewport") {
      padding: 0 gutter(strongest);
    }
  }

  .tabs-main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .tabs-section {
    background: #fff;
    flex: 1;
    padding: stack(strongest) gutter(strongest);
    min-height: 100vh;
  }

  .tabs-nav__trigger {
    &:hover {
      color: $back-office-color-secondary;

      .icon__glyph {
        fill: $back-office-color-secondary;
      }

      &:after {
        background: $back-office-color-secondary;
      }
    }
  }
  .tabs-nav__trigger--active {
    color: $back-office-color-secondary;

    &:after {
      background: $back-office-color-secondary;
    }
  }
}

.back-office-content-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: negative(stack(strongest)) 0 0 negative(gutter(strongest));
}

.back-office-content-menu__main {
  margin: stack(strongest) 0 0 gutter(strongest);
}

.back-office-content-menu__aside {
  order: 2;
  margin: stack(strongest) 0 0 gutter(strongest);
  min-width: 216px;
  max-width: 216px;
}

.back-office-content-menu__main {
  flex: 1;
}

.back-office-content-menu-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  & + .back-office-content-menu-nav {
    margin-top: stack(strongest);
  }
}

.back-office-content-menu-nav__item {
  & + .back-office-content-menu-nav__item {
    margin-top: stack();
  }

  & + .back-office-content-menu-nav__item--button {
    margin-top: stack(strongest);
  }
}

.back-office-content-menu-nav__item--button {
  & + .back-office-content-menu-nav__item--button {
    margin-top: stack(strong);
  }
}

.back-office-content-menu-nav__item--break {
  margin-bottom: stack(strongest);
}

.back-office-site-navigation-menu__heading {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  margin: 32px 0 0 24px;
  color: #fff;
}

/**
 *----------------------------------------
  SECTION
 *----------------------------------------
 */

.back-office-section {
  & + .back-office-section {
    margin-top: stack(strongest) + stack(strongest);
  }
}

.back-office-section--as-aside {
  max-width: $max-main-width;
}

.back-office-section__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 stack(strong);
}

.back-office-section__heading {
  margin: 0;
}

.back-office-section__header__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-office-section--panel {
  background: $color-panel-100;
  border-radius: radius(weak);
  max-width: $max-main-width;
  padding: stack(strong) gutter(strong);
}

.back-office-section--sub {
  padding: 32px;
  background: #fafcff;
  margin-left: -32px;
  margin-right: -32px;
  border-top: solid 2px #e9e9e9;
}

.back-office-section__main {
  // Remove as I don't want this style by default. Might be some admin UI minor bugs
  // background: #fff;
  // border: solid 2px #e9e9e9;
  // padding: 32px;
}

.back-office-section--group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -64px 0 0 -64px;
}

.back-office-section--aside {
  flex-direction: column;
  display: flex;
  flex: 1;
  margin: 64px 0 0 64px;
  min-width: $max-aside-width;

  .back-office-section__main {
    background: #fff;
    border: none;
    padding: 0;
  }

  .back-office-section__aside {
    margin-left: auto;
  }
}

.back-office-section__footer {
  margin-top: stack(strong);
}

.back-office-data-definition-section {
  & + .back-office-data-definition-section {
    margin-top: stack(strongest);
  }
}

.back-office-data-definition {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: $max-main-width;
  border-top: solid 2px $color-stroke;
  border-bottom: solid 2px $color-stroke;
  padding: stack(default-strong) 0;

  & + .back-office-data-definition {
    border-top: 0;
  }

  &:last-of-type {
    margin-bottom: stack(strong);
  }
}

.back-office-data-definition--unitary {
  border-top: 0;
  border-bottom: 0;
}

.back-office-data-definition__main {
  grid-column: 1/-1;

  .back-office-data-definition {
    &:first-of-type {
      padding-top: 0;
      border-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  // Quick specicifity fix for button group
  .back-office-data-definition__main__button-group {
    margin-bottom: 0;
  }
}

.back-office-data-definition__header {
  & + .back-office-data-definition__main {
    grid-column: auto;
  }
}

.back-office-data-definition__header {
  padding: 0 gutter(strong) 0 0;
}

.back-office-data-definition__small {
  color: $color-typography-meta;
  font-size: typography-size("meta");
  line-height: typography-line-height("meta");
  margin: 0;
}

.back-office-data-definition__description {
  font-weight: 600;
  margin: 0;
}
.back-office-data-definition__heading {
  font-size: typography-size("body");
  line-height: typography-line-height("body");
  margin: 0;
  font-weight: 500;

  & + .back-office-data-definition__small {
    margin-top: stack();
  }
}

.back-office-data-definition__footer {
  grid-column: 1 / -1;
}

/**
 * Restrict sticky menu to desktop, just to be safe
 */
@include mq("medium-viewport") {
  .back-office-content-menu__aside__content--sticky {
    position: sticky;
    top: stack(strong);
  }

  .back-office-content-menu__aside--sticky {
    position: sticky;
    top: stack(strong);
  }
}
