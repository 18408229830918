$color-typography: #022d35;
$color-site-background: #fff;
$color-typography-meta: #678186; // 60% of $color-typography
/**
 * =======================================================
 *
 * Primary
 *
 * Color name is "Caribbean Green".
 *
 * =======================================================
 */

$color-primary: #05c78b;
$color-on-primary: #fff;

$color-primary-900: #005e30;
$color-primary-800: #007d46;
$color-primary-700: #008e53;
$color-primary-600: #00a160;
$color-primary-500: #00b06b;
$color-primary-400: #01bc81;
$color-primary-300: #53c897;
$color-primary-200: #8bd7b4;
$color-primary-100: #bae7d1;
$color-primary-50: #e2f5ed;
$color-primary-10: #f3fbf8;

/**
 * =======================================================
 *
 * Secondary
 *
 * Color name is "Elephant".
 *
 * =======================================================
 */

$color-secondary: #14424b;
$color-on-secondary: #fff;

$color-secondary-900: #022d35;
$color-secondary-800: #14424b;
$color-on-secondary-800: #fff;
$color-secondary-700: #21555f;
$color-secondary-600: #2f6974;
$color-secondary-500: #3a7784;
$color-secondary-400: #578b97;
$color-secondary-300: #72a0ab;
$color-secondary-200: #96bcc5;
$color-secondary-100: #b8d9df;
$color-secondary-50: #d9f1f9;
$color-secondary-10: #f2f6f7;

/**
 * =======================================================
 *
 * Tertiary
 *
 * Color name is "Ecru".
 *
 * =======================================================
 */

$color-tertiary: #e0d9c9;
$color-on-tertiary: #460000;

$color-tertiary-900: #460000;
$color-tertiary-800: #420000;
$color-tertiary-700: #3f0000;
$color-tertiary-600: #3d0000;
$color-tertiary-500: #3a0000;
$color-tertiary-400: #421300;
$color-tertiary-300: #543300;
$color-tertiary-200: #81661d;
$color-tertiary-100: #b4a177;
$color-tertiary-50: #e0d9c9;
$color-tertiary-10: #f3f0e9;
$color-tertiary-5: #faf9f6;

/**
 * =======================================================
 *
 * Triadic
 *
 * Color name is "Ecru".
 *
 * =======================================================
 */

$color-triadic: #0262df;
$color-on-triadic: #fff;

$color-triadic-900: #1340c0;
$color-triadic-800: #0262df;
$color-triadic-700: #0074f2;
$color-triadic-600: #0087ff;
$color-triadic-500: #0096ff;
$color-triadic-400: #1aa6ff;
$color-triadic-300: #53b6ff;
$color-triadic-200: #8acbff;
$color-triadic-100: #b9dfff;
$color-triadic-50: #e2f2ff;

/**
 * =======================================================
 *
 * Alerts
 *
 * =======================================================
 */

$color-help: #0260df;
$color-on-help: #fff;
$color-help-background: #f5f9fe;
$color-help-on-background: #022d35;

$color-info: #0260df;
$color-on-info: #fff;
$color-info-background: #f5f9fe;
$color-info-on-background: #022d35;

$color-danger: #a73c46;
$color-on-danger: #fff;
$color-danger-background: #f8eff0;
$color-on-danger-background: #022d35;

$color-success: #05c78b;
$color-on-success: #fff;
$color-success-background: #ebfbf6;
$color-on-success-background: #022d35;

$color-warning: #d69a00;
$color-on-warning: #fff;
$color-warning-background: #fcf7eb;
$color-on-warning-background: #022d35;

$layout-shadow: 8px 0 24px 0 rgba(75, 79, 101, 0.2);

$panel-secondary-background: #f2f6f7;
$panel-on-secondary-background: #56586d;

$color-panel-100: #fafafa;
$color-panel-400: #e9e9e9;

$progress-bar-on-background: #01bc80;
$progress-bar-background: rgba(#e0d9c9, 0.4);

$color-icon-fill: #022d35;

$color-stroke: #f0f0f0;

$box-shadow: 0 4px 24px rgba(#022d35, 0.16);

/**
 * =======================================================
 *
 * New primary colour
 *
 * Color name is "Cadmium yellow" "Neon yellow".
 *
 * =======================================================
 */

$content-color-primary: #fff500;

/**
 * =======================================================
 *
 * New typography colours
 *
 * Color name is "Rich black".
 *
 * =======================================================
 */
$content-color-typography: #111;
$content-color-typography-meta: #545454;

$content-button-background: #111;
$content-button-on-background: #fff;
$content-button-background-hover: #545454;
$content-button-on-background-hover: #fff;

$content-box-shadow: 0 0 16px 8px rgba(#111111, 0.04);

$content-panel-200: #f9f9f9;
$content-panel-900: #111;
$content-panel-800: #313131;
$content-panel-700: #4f4f4f;
$content-panel-500: #898989;
$content-on-panel-900: #fff;
$content-on-panel-800: #fff;
$content-on-panel-700: #fff;
$content-on-panel-500: #fff;

$content-icon-fill: #111;

$content-button-action-background: #fff500;
$content-button-action-on-background: #111;
$content-button-action-background-hover: #fffb99;
$content-button-action-on-background-hover: #111;

$content-button-primary-background: #fff500;
$content-button-primary-on-background: #111;
$content-button-primary-background-hover: #fffb99;
$content-button-primary-on-background-hover: #111;

$content-button-secondary-background: #f9f9f9;
$content-button-secondary-on-background: #111;
$content-button-secondary-background-hover: #f3f3f3;
$content-button-secondary-on-background-hover: #111;
