.progress-menu {
  display: flex;
  flex-direction: column;
  margin: 0 0 stack(strongest);
}

.progress-menu__header {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  align-items: flex-start;
  margin-bottom: 4px;
}

.progress-menu__heading-link {
  align-items: center;
  display: inline-flex;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    margin-right: gutter(weak);
  }

  .icon__canvas {
    height: 20px;
    width: 20px;
  }
}

.progress-menu__heading-link__label {
  white-space: nowrap;
}

.progress-menu__aside__heading {
  font-weight: 400;
}

.progress-menu__aside {
  align-items: center;
  display: flex;
  font-weight: 400;
  margin-left: auto;
}

.progress-menu__aside__signifier {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  outline: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}

.progress-menu__aside__progress-label {
  font-weight: 500;
  display: inline-block;
}

.progress-menu__aside__counter-label {
  font-weight: 400;
  margin-left: gutter(weak);
}

.progress-menu__aside__signifier__icon {
  margin-left: gutter(weak);

  .icon__canvas {
    height: 20px;
    width: 20px;
  }
}

.progress-menu__linear-progress {
  position: relative;
}

.progress-menu__linear-progress__total {
  background: $progress-bar-background;
  border-radius: 2px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.progress-menu__linear-progress__determinate {
  @include transition();
  background: $progress-bar-on-background;
  border-radius: 2px;
  box-shadow: 2px 0 0 #fff;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.progress-menu__sections {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: stack() 0 stack(layout) negative(gutter(strongest));
  padding: 0;
}

.progress-menu__sections__item {
  margin-left: gutter(strongest);

  +.progress-menu__sections__item {
    margin-top: 8px;
  }
}

.progress-menu__sections__anchor {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.progress-menu__sections__anchor--active {
  font-weight: 600;
}

@include mq("medium-viewport") {
  .progress-menu__aside__heading {
    @include visually-unhidden();
  }

  .progress-menu__baspi-progress-tasks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}