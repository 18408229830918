


.body--landing {
  .site-account-main {
    padding-left: 0;
    padding-right: 0;
  }

  .site-account-main__content {
    max-width: 100%;
  }
  
  .site-account {
    padding: 0;
    max-width: 100%;

    @include mq("medium-viewport") {
      padding: 0;
    }
  }
}


.content-button {
  background-color: $content-button-background;
  border-color: $content-button-background;
  color: $content-button-on-background;
  border-radius: radius(weak-to-strong);

  &:hover {
    background-color: $content-button-background-hover;
    border-color: $content-button-background-hover;
    text-decoration: none;
  }
}

.content-button--anchor {

  .icon__canvas {
    width: 24px;
    height: 24px;
  }
}


.content-button--primary {
  background-color: $content-button-primary-background;
  border-color: $content-button-primary-background;
  color: $content-button-primary-on-background;
  border-radius: radius(weak-to-strong);

  &:hover {
    background-color: $content-button-primary-background-hover;
    border-color: $content-button-primary-background-hover;
    color: $content-button-primary-on-background;

    .icon__glyph {
      fill: $content-button-primary-on-background;
    }
  }

  .icon__glyph {
    fill: $content-button-primary-on-background;
  }
}

.content-button--secondary {
  background-color: $content-button-secondary-background;
  border-color: $content-button-secondary-background;
  color: $content-button-secondary-on-background;
  border-radius: radius(weak-to-strong);

  &:hover {
    background-color: $content-button-secondary-background-hover;
    border-color: $content-button-secondary-background-hover;
    color:$content-button-secondary-on-background-hover;
  }
}

.content-button--anchor {

  .icon__canvas {
    width: 24px;
    height: 24px;
  }
}


.content-button--stronger {
  font-size: 20px;
  line-height: 28px;
  border-radius: 12px;
  font-weight: 700;

  .button__main {
    padding: 12px 24px;
  }

  .icon__canvas {
    width: 28px;
    height: 28px;
  }
}


.content-list {
  list-style: none;
  color: $content-color-typography-meta;
  font-weight: 500;
  padding: 0;

  li {
    display: flex;
  }
}

.content-list__icon {
  margin-right: gutter();
  margin-top: 4px;

  .icon__canvas {
    width: 16px;
    height: 16px;
  }

  .icon__glyph {
    fill: $content-color-typography-meta;
  }
}

.content-list__subheading {
  display: block;
  font-weight: 700;
  color: $content-color-typography;
}

.content-copy--meta {
  color: $content-color-typography-meta;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
