
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.cookie-consent-management {
  margin: 0 0 stack(strongest);
}

.cookie-consent-management-category {
  border: solid 2px $color-stroke;
  border-width: 2px 0;

  & + .cookie-consent-management-category {
    border-width: 0 0 2px;
  }
}

.cookie-consent-management-category__header {
  padding: stack() 0;
  align-items: center;
  display: flex;
}

.cookie-consent-management-category__button {
  all: inherit;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
  flex: 1;
}

.cookie-consent-management-category__checkbox {
  .input-toggle__option__signifier {
    margin-right: 0;
  }

  .input-toggle__option__label {
    @include visually-hidden();
  }
}

.cookie-consent-management-category__main {
  padding: 0 gutter() 0 0;
}
