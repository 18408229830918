@import "./colours";
@import "./breakpoints";
@import "./button";
@import "./characters";
@import "./spacing";
@import "./landing";
@import "./transitions";
@import "./field";
@import "./input";
@import "./typography";
@import "./content-typography";

// Moving towards CSS vars

:root {
  --stroke-width: 2px;
  --stroke-color: #f0f0f0;
}
