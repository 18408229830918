
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

/**
 * 1. The bottom spacing prevents Chat widget from covering any footer text.
 */
.site-footer {
  background: $content-panel-900;
  color: $content-on-panel-900;
  margin: 80px 0 0;
  padding-top: stack(strongest) * 2;
  padding-bottom: stack(strongest) * 2; /* 1 */
  text-align: center;
}

.site-footer__content {
  margin: 0 auto;
  max-width: $layout-max-width;
}

.site-colophon {
  margin: 0;

  small {
    font-size: inherit;
    color: inherit;
  }
}

.site-footer__topics {
  display: flex;
  flex-direction: column;
}

.site-footer__topic {
  margin: 0 0 stack(strongest);

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.site-footer__topic__heading {
  display: block;
  font-size: typography-size("body");
  font-weight: 700;
  line-height: typography-line-height("body");
  margin: 0 0 stack();
}

.site-footer__topic__links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-footer__footnote {
  display: flex;
  flex-direction: column;
  margin-top: stack("strongest");
}

.site-footer__footnote__logo {
  height: 24px;
  width: auto;
  margin: 0 0 16px;
}

/**
 * 1. Pull out from the site-side gutter.
 */
@include mq("medium-viewport") {
  .site-footer {
    margin-left: negative(gutter(stronger)); /* 1 */
    margin-right: negative(gutter(stronger)); /* 1 */
    padding-left: 40px + gutter(stronger);
    padding-right: 40px + gutter(stronger);
    text-align: initial;
  }

  .site-footer__topics {
    margin-left: -80px;
    flex-direction: row;
    justify-content: space-between;
  }

  .site-footer__topic {
    margin-left: 80px;
  }

  .site-footer__topic--contact {
    margin-left: auto;
  }

  .site-footer__footnote {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .site-footer__footnote__logo {
    margin: 0;
  }
}

.site-footer__topic__links--column {
  @include mq("medium-viewport") {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
  }
}
