@import "../functions/utilities";
@import "../variables/colours";

$typography-sizes: (
  "heading-1": ("small-viewport": 32px,
    "medium-viewport": 32px,
  ),
  "heading-2": ("small-viewport": 24px,
    "medium-viewport": 24px,
  ),
  "heading-3": ("small-viewport": 20px,
    "medium-viewport": 20px,
  ),
  "heading-4": ("small-viewport": 18px,
    "medium-viewport": 18px,
  ),
  "heading-5": ("small-viewport": 16px,
    "medium-viewport": 16px,
  ),
  "body": ("small-viewport": 16px,
    "medium-viewport": 16px,
  ),
  "lead": ("small-viewport": 18px,
    "medium-viewport": 18px,
  ),
  "small": ("small-viewport": 14px,
    "medium-viewport": 14px,
  ),
  "meta": ("small-viewport": 14px,
    "medium-viewport": 14px,
  ),
);

$typography-leading: (
  "heading-1": ("small-viewport": 48px,
    "medium-viewport": 48px,
  ),
  "heading-2": ("small-viewport": 32px,
    "medium-viewport": 32px,
  ),
  "heading-3": ("small-viewport": 28px,
    "medium-viewport": 28px,
  ),
  "heading-4": ("small-viewport": 26px,
    "medium-viewport": 26px,
  ),
  "heading-5": ("small-viewport": 24px,
    "medium-viewport": 24px,
  ),
  "body": ("small-viewport": 24px,
    "medium-viewport": 24px,
  ),
  "lead": ("small-viewport": 26px,
    "medium-viewport": 26px,
  ),
  "small": ("small-viewport": 22px,
    "medium-viewport": 22px,
  ),
  "meta": ("small-viewport": 22px,
    "medium-viewport": 22px,
  ),
);

$typography-weights: (
  "heading-1": ("small-viewport": 600,
    "medium-viewport": 600,
  ),
  "heading-2": ("small-viewport": 600,
    "medium-viewport": 600,
  ),
  "heading-3": ("small-viewport": 500,
    "medium-viewport": 500,
  ),
  "heading-4": ("small-viewport": 500,
    "medium-viewport": 500,
  ),
  "heading-5": ("small-viewport": 500,
    "medium-viewport": 500,
  ),
  "body": ("small-viewport": 400,
    "medium-viewport": 400,
  ),
  "lead": ("small-viewport": 500,
    "medium-viewport": 500,
  ),
  "small": ("small-viewport": 400,
    "medium-viewport": 400,
  ),
  "meta": ("small-viewport": 500,
    "medium-viewport": 500,
  ),
);

$typography-font: (
  "heading-0": (Noah, Helvetica, Arial, sans-serif),
  "heading-1": (Noah, Helvetica, Arial, sans-serif),
  "heading-2": (Noah, Helvetica, Arial, sans-serif),
  "heading-3": (Noah, Helvetica, Arial, sans-serif),
  "heading-4": (Noah, Helvetica, Arial, sans-serif),
  "heading-5": (Noah, Helvetica, Arial, sans-serif),
  "body": (Noah, Helvetica, Arial, sans-serif),
  "lead": (Noah, Helvetica, Arial, sans-serif),
  "small": (Noah, Helvetica, Arial, sans-serif),
  "meta": (Noah, Helvetica, Arial, sans-serif),
);

$typography-color: $color-typography !default;
$typography-family: "Noah", Helvetica, Arial, sans-serif !default;
$typography-size: map-deep-get($typography-sizes,
    "body",
    "small-viewport"
  ) !default;
$typography-weight: map-deep-get($typography-weights,
    "body",
    "small-viewport"
  ) !default;
$typography-line-height: map-deep-get($typography-leading,
    "body",
    "small-viewport"
  ) !default;