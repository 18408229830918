.button.back-office-button {
  background-color: #0262df;
  border-color: #0262df;

  &:hover,
  &:active {
    background-color: #01459d;
    border-color: #01459d;
  }

  &:focus {
    box-shadow: 0 0 0px 4px rgba(#01459d, 0.16);
    background-color: #01459d;
    border-color: #01459d;
  }

  &:not(.button--weaker) .button__main {
    padding: 6px 12px;
  }
}

.button.back-office-button--strong {
  &:not(.button--weaker) .button__main {
    padding: 8px 12px;
  }
}

.button.back-office-button--inverse {
  background-color: #fff;
  border-color: #fff;
  color: #0262df;

  &:hover,
  &:active {
    background-color: #01459d;
    border-color: #01459d;
  }

  &:focus {
    box-shadow: 0 0 0px 4px rgba(#01459d, 0.16);
    background-color: #01459d;
    border-color: #01459d;
  }

  .icon__glyph {
    fill: #0262df;
  }
}

.button.back-office-button.button--icon-only {
  .button__main {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.back-office-content__main--modal {
  padding: 32px;
  display: flex;
}

.back-office-content-modal {
  background: #fff;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(34, 34, 34, 0.08);
  display: flex;
  margin: 32px auto;
  max-width: 960px;
  width: 100%;
  padding: 32px;

  .breadcrumbs-menu {
    margin-bottom: stack(strong);
  }
}

.back-office-button.back-office-button--destructive {
  background: $color-danger;
  border-color: $color-danger;
  color: $color-on-danger;

  &:hover {
    background: $color-danger-background;
    border-color: $color-danger-background;
    color: $color-danger;

    .icon__glyph {
      fill: $color-danger;
    }
  }

  .icon__glyph {
    fill: $color-on-danger;
  }
}
