.copy--strong {
  font-weight: 500;
}

.copy--emphasis {
  font-style: italic;
}

.page-title {
  @include typography("heading-1");
  margin-bottom: stack(strongest);
}

.page__subtitle {
  font-size: typography-size("heading-2");
  line-height: typography-line-height("heading-2");
}

.copy--lead,
.page-lead {
  font-size: typography-size("lead");
  line-height: typography-line-height("lead");
  font-weight: typography-weight("lead");
  margin-bottom: stack(strongest);
  max-width: $max-main-width;
}

.page-title {
  & + .page-lead {
    margin-top: negative(stack(strong));
  }
}

.page-masthead {
  margin-bottom: stack("strongest");
}

.heading-2 {
  font-size: typography-size("heading-2");
  font-weight: typography-weight("heading-2");
  line-height: typography-line-height("heading-2");
}

.heading-3 {
  font-size: typography-size("heading-3");
  font-weight: typography-weight("heading-3");
  line-height: typography-line-height("heading-3");
}

.heading-4 {
  font-size: typography-size("heading-4");
  font-weight: typography-weight("heading-4");
  line-height: typography-line-height("heading-4");
  margin-bottom: stack();
}

.heading-5 {
  font-size: typography-size("body");
  font-weight: 500;
  line-height: typography-line-height("body");
  margin-bottom: stack();
}

.heading-6 {
  font-size: typography-size("body");
  font-weight: 400;
  line-height: typography-line-height("body");
  margin-bottom: 0;
}

.heading--surtitle {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-typography-meta;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: 0.05em;
}

.heading--surtitle--offset {
  margin-bottom: 4px + stack(strong);
}

.copy--weight-normal {
  font-weight: 400;
}

.copy--group {
  white-space: nowrap;
}

.copy--meta {
  color: $color-typography-meta;
}

.copy--bold {
  font-weight: 600;
}

.copy--extra-bold {
  font-weight: 700;
}

.copy--center {
  text-align: center;
}

.copy--pending {
  display: flex;
  font-weight: 500;

  .pending {
    margin-right: gutter();
  }
}

.address {
  list-style: none;
  padding: 0;
}

.copy--clamped {
  max-width: $max-content-width;
}

.copy--strong-stack {
  margin-bottom: stack(strong);
}

.copy--strongest-stack {
  margin-bottom: stack(strongest);
}

.copy--no-stack {
  margin-bottom: 0;
}

.copy--color-primary {
  color: $color-primary;
}

.copy--small {
  font-size: typography-size("meta");
  line-height: typography-line-height("meta");
}

.list--alpha {
  list-style: lower-alpha;
}

.list--strongest-stack {
  li {
    margin-bottom: stack(strongest);
  }
}

.anchor--small {
  font-size: typography-size("meta");
  line-height: typography-line-height("meta");

  .anchor__icon {
    .icon__canvas {
      width: typography-size("meta");
      height: typography-size("meta");
    }
  }
}

.anchor--icon {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  white-space: nowrap;

  .anchor__icon {
    display: inline-flex;
  }

  .anchor__label {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.anchor__icon {
  margin-right: gutter(weak);
  vertical-align: baseline;

  .icon__canvas {
    height: auto;
    width: 16px;
    vertical-align: middle;
  }
}

.anchor__icon--post {
  margin-right: 0;
  margin-left: gutter(weak);
}

.anchor__icon--prepend {
  margin-left: 0;
  margin-right: gutter(weak);
}

.success-list {
  padding: 0;
  list-style: none;
}

.success-list__item {
  display: flex;

  & + .success-list__item {
    margin-top: stack();
  }
}

.success-list__icon {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  margin-right: gutter(weak);
  margin-left: negative(gutter(weak));
  display: inline-flex;

  .icon__canvas {
    width: 16px;
    height: 16px;
  }
}

.success-list__item__heading {
  display: block;
  font-weight: 600;
}

.success-list--strong {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;

  strong {
    font-weight: 600;
  }

  .icon__canvas {
    width: 20px;
    height: 20px;
  }

  .success-list__item + .success-list__item {
    margin-top: stack(strong);
  }
}

.layout--section {
  margin-bottom: stack(strongest) + stack(strongest);
}

.layout--landing-width {
  max-width: $max-landing-width;
}
