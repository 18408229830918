
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.cookie-consent {
  background: #fff;
  border-radius: radius();
  bottom: gutter();
  box-shadow: $layout-shadow;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  left: gutter();
  max-height: 100vh;
  max-width: $max-content-width;
  overflow: auto;
  padding: stack(strong) gutter(strong) 0;
  position: fixed;
  width: calc(100% - gutter(strong));
  z-index: elevation("max");
}

.cookie-consent__button-group__item {
  width: calc(50% - gutter(strong));
}

.cookie-consent__button {
  width: 100%;
}

@include mq("medium-viewport") {
  .cookie-consent {
    bottom: gutter(strong);
    left: gutter(strong);
  }

  .cookie-consent__button-group__item {
    width: initial;
  }

  .cookie-consent__button {
    width: initial;
  }
}
