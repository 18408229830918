
        @use "sass:math";
        @import "./src/assets/styles/variables/index";
        @import "./src/assets/styles/functions/index";
        @import "./src/assets/styles/mixins/index";
        

.avatar {
  display: inline-flex;
}

.avatar__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  border: solid 2px #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
