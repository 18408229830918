*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  background-color: $color-site-background;
  box-sizing: border-box;
  height: 100%;
}

/**
 * 1. Used for landing page layouts to cut off the offset MacBook.
 */
body {
  background-color: $color-site-background;
  color: $typography-color;
  font-family: $typography-family;
  font-size: $typography-size;
  font-weight: $typography-weight;
  line-height: $typography-line-height;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* 1 */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: typography-size("body");
  font-weight: typography-weight("body");
  line-height: typography-line-height("body");
  margin: 0 0 stack(layout);
}

h1 {
  font-size: typography-size("heading-1");
  font-weight: typography-weight("heading-1");
  line-height: typography-line-height("heading-1");
}

h2 {
  font-size: typography-size("heading-2");
  font-weight: typography-weight("heading-2");
  line-height: typography-line-height("heading-2");
}

h3 {
  font-size: typography-size("heading-3");
  font-weight: typography-weight("heading-3");
  line-height: typography-line-height("heading-3");
}

h3 {
  font-size: typography-size("heading-3");
  font-weight: typography-weight("heading-3");
  line-height: typography-line-height("heading-3");
  margin-bottom: stack();
}

h5 {
  font-size: typography-size("heading-5");
  font-weight: typography-weight("heading-5");
  line-height: typography-line-height("heading-5");
  margin-bottom: 0;
}

p {
  margin: 0 0 stack(strong);
}

ol,
ul {
  margin: 0 0 stack(strong);
  padding: 0 0 0 gutter(strong);
}

li + li {
  margin-top: stack("weak");
}

dl {
  margin: 0 0 stack(strong);
}

/**
* 1. Remove gaps in links underline in iOS 8+ and Safari 8+.
*/
a {
  color: inherit;
  text-decoration: underline;
  -webkit-text-decoration-skip: objects;
  /* 1 */

  &:hover {
    text-decoration: none;
  }
}

strong {
  font-weight: 500;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

svg {
  vertical-align: bottom;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  margin: 0 0 stack(strong);
  width: 100%;
}

th,
td {
  text-align: left;
  font-weight: 400;
}

small {
  font-size: typography-size("small");
  font-weight: typography-weight("small");
  line-height: typography-line-height("small");
  color: $color-typography-meta;
  display: inline-block;
}

code {
  background-color: #f6f6f6;
  border-radius: 4px;
  padding: 1px 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
